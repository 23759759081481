import useSWR, { type SWRConfiguration } from 'swr'

import { useAuthStore } from '../store/authStore.ts'
import type { Workspace } from './types/workspace.ts'
import type { TransferError } from '../utils/Error/index.ts'
import { HOURS } from '../constants/time.ts'

const query: string = `
query AllWorkspaces {
  allWorkspaces {
      id
      name
  }
}
`

// Default refresh interval
const refreshInterval = 1 * HOURS

export type WorkspacesData = { allWorkspaces: Workspace[] }

/**
 * [List of Workspaces]{@link https://docs.datacake.de/api/graphql-api/using-graphql#list-of-workspaces}
 */
export function useWorkspaces(shouldFetch: boolean = true, config?: SWRConfiguration<WorkspacesData, TransferError>) {
  const apiToken = useAuthStore((state) => state.apiToken)

  const { data, error, isLoading, isValidating, mutate } = useSWR<WorkspacesData, TransferError>(
    shouldFetch ? { query, apiToken } : null,
    {
      refreshInterval: refreshInterval,
      dedupingInterval: refreshInterval,
      ...config,
    }
  )

  return {
    data: data ? data.allWorkspaces : undefined,
    error,
    isLoading,
    isValidating,
    mutate,
  }
}

import type { FunctionComponent } from 'preact'
import { IonIcon } from '@ionic/react'
import { hourglassOutline, warningOutline, informationCircleOutline } from 'ionicons/icons'

import './ContentMessage.css'

const icons = {
  note: informationCircleOutline,
  warning: warningOutline,
  loading: hourglassOutline,
} as const

/**
 * Mesage in content area
 * Icons based on [GitHub Alerts]{@link https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax#alerts}
 */
export const ContentMessage: FunctionComponent<{
  message: string
  type?: keyof typeof icons
}> = ({ message, type }) => (
  <div className="sb-center-wrapper">
    <p className="sb-content-message ion-margin">
      {/** Icon */}
      {type && <IonIcon aria-hidden="true" className="sb-content-message__icon" icon={icons[type]} size="small" />}
      {/** Message */}
      <span>{message}</span>
    </p>
  </div>
)

import type {
  ListItemApiDevice,
  ListItemDevice,
  WasteListItemDevice,
  NoiseListItemDevice,
  SoilListItemDevice,
  WasteDevice,
  NoiseDevice,
  SoilLevice,
} from '../types/device.ts'

import { MilesightEM310UDLDeviceProductName as WasteDeviceProductName } from '../product/milesight-em310-udl/device.ts'
import { MilesightWS302DeviceProductName as NoiseDeviceProductName } from '../product/milesight-ws302/device.ts'
import { SeeedSenseCAPS2104DeviceProductName as SoilDeviceProductName } from '../product/seeed-sensecap-s2014/device.ts'

/**
 * Make sure device contains measurements we need.
 * @note Datacake doesn't provide options to filter devices, just measurements
 * @note There may be different products with the same name (hardware)
 *
 * Milesight EM310-UDL
 *   slug: milesight-em310-udl-68
 *   id: b6d58698-ef04-4136-946d-167af9428899
 *
 * Milesight WS302
 *   slug: milesight-ws302-21
 *   id: a452f641-5185-4087-9136-56598dce8fa0
 *
 * Seeed SenseCAP S2014
 *   slug: seeed-sensecap-s2014-1
 *   id: 480fb833-5828-4395-afd7-754db13d226b
 */
export function isListItemApiDeviceSupported(listItemApiDevice: ListItemApiDevice): boolean {
  return [WasteDeviceProductName, NoiseDeviceProductName].includes(listItemApiDevice.product.name)
}

export function isWasteListItemDevice<SubType extends WasteListItemDevice>(
  listItemDevice: ListItemDevice
): listItemDevice is SubType {
  return listItemDevice.product.name === WasteDeviceProductName
}

export const isWasteDevice = isWasteListItemDevice<WasteDevice>

export function isNoiseListItemDevice<SubType extends NoiseListItemDevice>(
  listItemDevice: ListItemDevice
): listItemDevice is SubType {
  return listItemDevice.product.name === NoiseDeviceProductName
}

export const isNoiseDevice = isNoiseListItemDevice<NoiseDevice>

export function isSoilListItemDevice<SubType extends SoilListItemDevice>(
  listItemDevice: ListItemDevice
): listItemDevice is SubType {
  return listItemDevice.product.name === SoilDeviceProductName
}

export const isSoilDevice = isSoilListItemDevice<SoilLevice>

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import type { LatLngBounds } from 'leaflet'

import type { Position } from '@capacitor/geolocation'

export interface MapStore {
  mapBounds: { [workspaceId: string]: LatLngBounds | undefined }
  setMapBounds: (workspaceId: string, mapBounds: LatLngBounds | undefined) => void

  isMyPositionEnabled: boolean
  setIsMyPosititionEnabled: (isMyPositionEnabled: boolean) => void

  myPosition: Position | null
  setMyPosition: (myPosition: Position) => void
}

export const useMapStore = create<MapStore>()(
  devtools(
    (set) => ({
      mapBounds: {},
      setMapBounds: (workspaceId, mapBounds) =>
        set((state) => ({
          mapBounds: {
            ...state.mapBounds,
            [workspaceId]: mapBounds,
          },
        })),

      isMyPositionEnabled: false,
      setIsMyPosititionEnabled: (isMyPositionEnabled) => set({ isMyPositionEnabled }),

      myPosition: null,
      setMyPosition: (myPosition) => set({ myPosition }),
    }),
    {
      name: document.title,
      store: 'MapStore',
    }
  )
)

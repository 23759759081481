/**
 * Based on Guzzle Exceptions
 * @see https://docs.guzzlephp.org/en/stable/quickstart.html#exceptions
 */
export default abstract class TransferError extends Error {
  public name = 'TransferError'

  /**
   * @inheritdoc
   */
  constructor(
    public request: Request,
    message?: string,
    options?: ErrorOptions
  ) {
    super(message, options)

    // V8 only, doesn't prepend cause
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, new.target)
    }

    /**
     * Set the prototype explicitly on when compilation target is ES5.
     * @see https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
     */
    Object.setPrototypeOf(this, TransferError.prototype)
  }
}

export default class AuthError extends Error {
  public name = 'AuthError'

  /**
   * @inheritdoc
   */
  constructor(message: string = 'Invalid credentials') {
    super(message)

    Object.setPrototypeOf(this, AuthError.prototype)
  }
}

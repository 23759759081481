import { ConnectError, ApiResponseError, ApiGraphQLError } from './Error/index.ts'

type GraphQLPayloadSuccess<T> = {
  data: T
}

type GraphQlPayloadError = {
  errors: Array<{
    message: string
    locations: Array<{ line: number; column: number }>
  }>
}

type GraphQLPayload<T> = GraphQLPayloadSuccess<T> | GraphQlPayloadError

type Key = {
  query: string
  operationName?: string
  variables?: Record<string, string>
  apiToken?: string
}

export type ApiFetcher = <Data = unknown>(key: Key) => Promise<Data>

export function createFetcher(url: string): ApiFetcher {
  /**
   * @link https://swr.vercel.app/docs/arguments#passing-objects
   */
  return async ({ query, operationName, variables, apiToken }) => {
    const request = new Request(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        ...(apiToken && { authorization: `Token ${apiToken}` }),
      },
      body: JSON.stringify({
        operationName,
        query,
        variables,
      }),
    })

    let response: Response

    try {
      response = await fetch(request)
    } catch (typeError) {
      throw new ConnectError(request, { cause: typeError as TypeError })
    }

    if (!response.ok) {
      throw new ApiResponseError(request, response, 'Invalid response')
    }

    if (response.headers.get('content-type') !== 'application/json') {
      throw new ApiResponseError(request, response, 'Invalid content type')
    }

    /** @throws {SyntaxError} */
    const payload: GraphQLPayload<any> = await response.json()

    // GraphQL Error
    if ('errors' in payload) {
      throw new ApiGraphQLError(request, response, payload.errors)
    }

    return payload.data
  }
}

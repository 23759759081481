import { render } from 'preact'
import { App } from './App.tsx'

// Enable mocking
if (import.meta.env.DEV) {
  const { worker } = await import('./mocks/browser.ts')

  await worker.start({
    onUnhandledRequest: (request, print) =>
      request.url.startsWith(import.meta.env.VITE_DATACAKE_API_TOKEN) && print.warning(),
  })
}

render(<App env={import.meta.env} />, document.getElementById('app')!)

import type { ApiDevice, ListItemDevice, Device } from '../../types/device.ts'

import { MilesightEM310UDLMeasurent } from './measurement.ts'
import type { MilesightEM310UDLConfigurationValue } from './configurationValue.ts'

export const MilesightEM310UDLDeviceProductName = 'Milesight EM310-UDL'

export type MilesightEM310UDLApiDevice = ApiDevice<MilesightEM310UDLMeasurent>

export type MilesightEM310UDLListItemDevice = ListItemDevice<MilesightEM310UDLMeasurent>
export type MilesightEM310UDLDevice = Device<MilesightEM310UDLListItemDevice, MilesightEM310UDLConfigurationValue>

import type { FunctionComponent } from 'preact'
import { IonButton } from '@ionic/react'
import { IonButton as IonButtonElement } from '@ionic/core/components/ion-button'

/**
 * Open directions in Google Maps
 * [Maps URLs > Directions]{@link {https://developers.google.com/maps/documentation/urls/get-started#directions-action}
 */
export const DirectionsButton: FunctionComponent<{
  destination: { lat: number; lng: number }
}> = ({ destination }) => {
  const queryParams = new URLSearchParams({
    api: '1',
    destination: [destination.lat, destination.lng].join(','),
    travelmode: 'driving',
    dir_action: 'navigate',
  })

  /**
   * Handle click: Open in a new window
   * Desktop: Restrict window features so user has to close it when done
   * Mobile: New window opens up, but while Google Maps app opens, it's closed instantly
   * Unfortunately google maps prevents from being opened inside an iframe
   */
  const handleClick: React.PointerEventHandler<HTMLIonButtonElement> = (pointerEvent) => {
    if (pointerEvent.target instanceof IonButtonElement && pointerEvent.target.href) {
      pointerEvent.preventDefault()

      window.open(pointerEvent.target.href, pointerEvent.target.target, 'popup,noopener,noreferrer')
    }
  }

  return (
    <IonButton
      fill="clear"
      href={`https://www.google.com/maps/dir/?${queryParams}`}
      target="destination"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      {'Navigate'}
    </IonButton>
  )
}

import { get, set, del, createStore } from 'idb-keyval'
import type { PersistStorage } from 'zustand/middleware'

/**
 * IDB Storage without using JSON methods (parse, stringify)
 * @link https://docs.pmnd.rs/zustand/integrations/persisting-store-data#how-can-i-use-a-custom-storage-engine
 * @example Usage with zustand persist middleware
 * ```ts
 * {
 *   name: 'state',
 *   storage: createIdbStorage('@smartbin/client/state', 'keyval'),
 *   // Required to omit state functions
 *   partialize: ({ foobar }) => ({ foobar })
 * }
 * ```
 */
export function createIdbStorage<S>(
  /** Database name for exclusive use by state */
  dbName = 'keyval-store',
  /** Store name */
  storeName = 'keyval'
): PersistStorage<S> {
  const store = createStore(dbName, storeName)

  return {
    getItem: async (name) => (await get(name, store)) ?? null,
    setItem: (name, value) => set(name, value, store),
    removeItem: (name) => del(name, store),
  }
}

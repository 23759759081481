import type { FunctionComponent } from 'preact'
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
} from '@ionic/react'
import { useParams } from 'react-router'

import { useDevice } from '../../data/device.ts'
import { useIonViewVisibility } from '../../hooks/useIonViewVisibility.ts'
import { formatDateTime } from '../../utils/date.ts'

import type { ApiMeasurement, LoraRSSI as LoraRSSIMeasurement } from '../../data/types/measurement.ts'
import { isWasteDevice } from '../../data/filters/device.ts'
import { Page } from '../../components/Page/Page.tsx'
import { FetchHandler } from '../../components/FetchHandler/FetchHandler.tsx'
import { DirectionsButton } from '../../components/DirectionsButton/DirectionsButton.tsx'
import { FillLevelChart } from '../../components/FillLevelChart/FillLevelChart.tsx'

export const DevicePage: FunctionComponent = () => {
  // Note: Sometimes id may be undefined (at least in dev)
  const { id } = useParams<{ id: string }>()

  const isViewVisible = useIonViewVisibility()

  const { data: device, error, isLoading, mutate } = useDevice(id, isViewVisible)

  return (
    <Page title={'Device'} onRefresh={mutate}>
      {device ? (
        <IonCard>
          <IonCardHeader>
            {/** Location */}
            {device.location ? (
              <IonCardTitle>{device.location}</IonCardTitle>
            ) : (
              <IonCardTitle color="medium">{'Location description missing'}</IonCardTitle>
            )}
            {/** Metadata */}
            <IonCardSubtitle>
              <span>Online: {device.online ? 'Yes' : 'No'}</span> |{' '}
              <span>Last activity: {device.lastHeard ? formatDateTime(device.lastHeard) : '-'}</span>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              {/** Measurements */}
              <IonItemGroup>
                <IonItemDivider>
                  <IonLabel>{'Measurements'}</IonLabel>
                </IonItemDivider>
                {Object.values(device.measurements).map((measurement, index, items) => (
                  <IonItem key={measurement.field.fieldName} lines={index + 1 === items.length ? 'none' : undefined}>
                    <IonLabel color={measurement.field.role ? undefined : 'medium'}>
                      {measurement.field.verboseFieldName}
                    </IonLabel>
                    <div>
                      <span>{formatValue(measurement)}</span>
                      {measurement.field.unit && <span> {measurement.field.unit}</span>}
                      {measurement.field.fieldName === 'LORA_RSSI' && (
                        <span> ({formatSignal(measurement as LoraRSSIMeasurement)})</span>
                      )}
                    </div>
                  </IonItem>
                ))}
              </IonItemGroup>
              {/** Configuration */}
              <IonItemGroup>
                <IonItemDivider>
                  <IonLabel>{'Configuration'}</IonLabel>
                </IonItemDivider>
                {Object.values(device.configuration).map((configuration, index, items) => (
                  <IonItem
                    key={configuration.configurationField.fieldName}
                    lines={index + 1 === items.length ? 'none' : undefined}
                  >
                    <IonLabel>{configuration.configurationField.verboseFieldName}</IonLabel>
                    <div>
                      <span>{configuration.valueNumber}</span>
                      {configuration.configurationField.unit && <span> {configuration.configurationField.unit}</span>}
                    </div>
                  </IonItem>
                ))}
              </IonItemGroup>
            </IonList>
          </IonCardContent>

          {/** Last unloaded */}
          {isWasteDevice(device) && (
            <IonCardContent>
              <p>{'Last unloaded at:'}</p>
              {device.measurements['UNLOADED_AT'].value === 0 ? (
                <dl>
                  <dt>{'Time: '}</dt>
                  <dd>{'N/A'}</dd>
                  <dt>{'Level:'}</dt>
                  <dd>{'N/A'}</dd>
                </dl>
              ) : (
                <dl>
                  <dt>{'Time: '}</dt>
                  <dd>{formatDateTime(device.measurements['UNLOADED_AT'].modified)}</dd>
                  <dt>{'Level:'}</dt>
                  <dd>{device.measurements['UNLOADED_AT'].value} %</dd>
                </dl>
              )}
            </IonCardContent>
          )}

          {/** History */}
          {isWasteDevice(device) && (
            <IonCardContent>
              <FillLevelChart history={device.history} />
            </IonCardContent>
          )}

          {/** Directions */}
          {device.position && <DirectionsButton destination={device.position} />}
        </IonCard>
      ) : (
        <FetchHandler error={error} isLoading={isLoading} isNotFound={device === null} />
      )}
    </Page>
  )
}

/**
 * Format measurement value
 */
function formatValue(measurement: ApiMeasurement): string {
  if (measurement.field.fieldType === 'INT') {
    return measurement.value.toFixed(0)
  }

  if (measurement.field.fieldType === 'FLOAT') {
    return measurement.value.toFixed(2)
  }

  // BOOLEAN | GEO | STRING
  return measurement.valueString
}

/**
 * @todo Use only LoraRSSI type
 * @see https://sensing-labs.com/f-a-q/a-good-radio-level/
 */
function formatSignal(loraRssiMeasurement: LoraRSSIMeasurement): string {
  if (loraRssiMeasurement.value > -120) {
    return 'good'
  }

  return 'bad'
}

import type { FunctionComponent } from 'preact'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

import { ApiProvider } from './data/ApiContext.tsx'
import { PersistentStateProvider } from './components/PersistentStateProvider/PersistentStateProvider.tsx'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary.tsx'

import { useAuthStore } from './store/authStore.ts'
import { routes } from './navigation/index.ts'
import { Menu } from './components/Menu/Menu.tsx'
import { useFirstWorkspace } from './hooks/useFirstWorkspace.ts'

// Pages
import { DevicesPage } from './pages/Devices/DevicesPage.tsx'
import { DevicePage } from './pages/Device/DevicePage.tsx'
import { MapPage } from './pages/Map/MapPage.tsx'
import { NotFoundPage } from './pages/NotFound/NotFoundPage.tsx'
import { SignInPage } from './pages/SignIn/SignInPage.tsx'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/**
 * Ionic Dark and High Contrast Palettes
 * -----------------------------------------------------
 * For more information, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 * https://ionicframework.com/docs/theming/high-contrast-mode
 */
import '@ionic/react/css/palettes/dark.system.css'
import '@ionic/react/css/palettes/high-contrast.system.css'
import '@ionic/react/css/palettes/high-contrast-dark.system.css'

/* Theme variables */
import './theme/variables.css'

/* Custom */
import './theme/theme.css'

setupIonicReact({
  toastDuration: 3_000,
})

export const Panel: FunctionComponent = () => {
  const apiToken = useAuthStore((state) => state.apiToken)

  useFirstWorkspace(apiToken !== null)

  if (!apiToken) {
    return <SignInPage />
  }

  return (
    <IonReactRouter>
      <IonSplitPane contentId="main" when="sm">
        <Menu />
        <IonRouterOutlet id="main">
          <Redirect exact from={routes.start} to={routes.devices} />
          <Route path={routes.devices} exact component={DevicesPage} />
          <Route path={routes.device} exact component={DevicePage} />
          <Route path={routes.devicesMap} exact component={MapPage} />
          <Route component={NotFoundPage} />
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  )
}

export const App: FunctionComponent<{ env: ImportMetaEnv }> = ({ env }) => {
  return (
    <IonApp>
      <ErrorBoundary>
        <PersistentStateProvider fallback={undefined}>
          <ApiProvider
            apiUrl={env.VITE_DATACAKE_GRAPHQL_API_URL}
            refreshInterval={Number.parseFloat(env.VITE_DATACAKE_GRAPHQL_API_REFRESH_INTERVAL) * 60_000}
          >
            <Panel />
          </ApiProvider>
        </PersistentStateProvider>
      </ErrorBoundary>
    </IonApp>
  )
}

import type { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import { useAuthStore } from '../../store/authStore.ts'

/**
 * Show fallback until persistent state is fully hydrated
 * @link https://docs.pmnd.rs/zustand/integrations/persisting-store-data#how-can-i-check-if-my-store-has-been-hydrated
 */
export const PersistentStateProvider: FunctionComponent<{
  fallback?: JSX.Element
}> = ({ fallback, children }) => {
  const [hydrated, setHydrated] = useState(useAuthStore.persist.hasHydrated)

  useEffect(() => {
    const unsubHydrate = useAuthStore.persist.onHydrate(() => setHydrated(false))
    const unsubFinishHydration = useAuthStore.persist.onFinishHydration(() => setHydrated(true))

    setHydrated(useAuthStore.persist.hasHydrated())

    return () => {
      unsubHydrate()
      unsubFinishHydration()
    }
  }, [])

  return <>{hydrated ? children : fallback}</>
}

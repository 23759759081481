import type { ListItemApiDevice, ListItemDevice, ApiDevice, Device } from '../types/device.ts'
import type { ApiMeasurement } from '../types/measurement.ts'
import type { ApiConfigurationValue } from '../types/configurationValue.ts'

import type { FieldRole } from '../types/measurement.ts'

const rolesOrder: Array<FieldRole> = ['PRIMARY', 'SECONDARY', 'DEVICE_SIGNAL', 'DEVICE_BATTERY', null]

/**
 * Normalize raw api list item device
 */
export function transformListItemDevice(apiDevice: ListItemApiDevice): ListItemDevice<ApiMeasurement> {
  return {
    id: apiDevice.id,
    serialNumber: apiDevice.serialNumber,
    verboseName: apiDevice.verboseName,
    location: apiDevice.location || null,
    position: resolveDeviceCurrentPosition(apiDevice),
    metadata: JSON.parse(apiDevice.metadata),
    online: apiDevice.online,
    lastHeard: apiDevice.lastHeard ? new Date(apiDevice.lastHeard) : null,
    measurements: Object.fromEntries(
      apiDevice.currentMeasurements
        // Sort
        .sort(
          (a, b) =>
            // By order
            rolesOrder.indexOf(a.field.role) - rolesOrder.indexOf(b.field.role) ||
            // Fall back to identifier
            a.field.fieldName.localeCompare(b.field.fieldName)
        )
        // TODO: Transform measurement
        // Parse date
        .map((measurement) => ({ ...measurement, modified: new Date(measurement.modified) }))
        // Create entries
        .map((measurement) => [measurement.field.fieldName, measurement])
    ),
    product: apiDevice.product,
  }
}

/**
 * Normalize raw api single device
 */
export function transformDevice(apiDevice: ApiDevice): Device<ListItemDevice<ApiMeasurement>, ApiConfigurationValue> {
  const historyItems: Array<{ time: string } & Record<string, any>> = JSON.parse(apiDevice.history)

  return {
    ...transformListItemDevice(apiDevice),
    configuration: Object.fromEntries(
      apiDevice.currentConfigurationValues
        // Create entries
        .map((configurationValue) => [configurationValue.configurationField.fieldName, configurationValue])
    ),
    history: historyItems.map(({ time, ...measurements }) => ({
      time: new Date(time),
      measurements,
    })),
  }
}

/**
 * Decode manual position from format '(lat,lng)', fall back to GPS location when available
 */
function resolveDeviceCurrentPosition(apiDevice: ListItemApiDevice): Record<'lat' | 'lng', number> | null {
  const manualPositionRegExp = new RegExp('^\\((?<lat>.+),(?<lng>.+)\\)$')

  // Find field by known enum, ignore value of (0.000000, 0.000000)
  const manualLocationMeasurement = apiDevice.currentMeasurements.find(
    ({ field, valueString }) => field.role === 'DEVICE_LOCATION' && field.fieldType === 'GEO' && valueString
  )
  const manualPositionRxMatch = manualLocationMeasurement?.valueString.match(manualPositionRegExp)

  // Manual
  if (manualPositionRxMatch?.groups) {
    return {
      lat: Number.parseFloat(manualPositionRxMatch.groups.lat),
      lng: Number.parseFloat(manualPositionRxMatch.groups.lng),
    }
  }

  // GPS sensor
  if (apiDevice.currentLocation.lat && apiDevice.currentLocation.lng) {
    return apiDevice.currentLocation
  }

  // Not available
  return null
}

import { useRef, useState, useLayoutEffect } from 'preact/hooks'

/**
 * @param {string} mediaQueryString - For example (prefers-color-scheme: dark)
 * @return {boolean} False when doesn't match
 * @note When using useSyncExternalStore makes too many invocations to getSnaphot
 */
export const useMediaQuery = (mediaQueryString: string): boolean => {
  const mediaQueryListRef = useRef<MediaQueryList>(window.matchMedia(mediaQueryString))
  const [matches, setMatches] = useState<boolean>(mediaQueryListRef.current.matches)

  useLayoutEffect(() => {
    const mediaQueryList = mediaQueryListRef.current

    const handleChange = () => setMatches(mediaQueryList.matches)

    mediaQueryList.addEventListener('change', handleChange)

    return () => mediaQueryList.removeEventListener('change', handleChange)
  }, [])

  return matches
}

import ApiResponseError from './ApiResponseError.ts'

type ApiGraphQLPayloadError = {
  message: string
  locations: Array<{ line: number; column: number }>
}

export default class ApiGraphQLError extends ApiResponseError {
  public name = 'ApiGraphQLError'

  /**
   * @inheritdoc
   */
  constructor(
    request: Request,
    response: Response,
    public errors?: ApiGraphQLPayloadError[]
  ) {
    super(request, response, 'GraphQL error')

    Object.setPrototypeOf(this, ApiGraphQLError.prototype)
  }
}

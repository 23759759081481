import useSWR, { type SWRConfiguration } from 'swr'
import { useMemo } from 'preact/hooks'

import { useAuthStore } from '../store/authStore.ts'
import type { ApiDevice } from './types/device.ts'
import { transformDevice } from './transformers/device.ts'
import { isListItemApiDeviceSupported } from './filters/device.ts'
import { useRevalidateOnViewEnter } from '../hooks/useRevalidateOnViewEnter.ts'
import type { TransferError } from '../utils/Error/index.ts'

/**
 * @note Possible to skip history with `history() @skip(if: $skipHistory)`
 *       or include with  `history() @include(if: $includeHistory)`
 */
const query: string = `
  query Device(
    $deviceId: String!,
    $timerangestart: String!,
    $timerangeend: String!,
    $resolution: String!
  ) {
    device(deviceId: $deviceId) {
      id
      serialNumber
      verboseName
      location
      currentLocation {
        lat
        lng
      }
      metadata
      online
      lastHeard
      currentMeasurements(
        allActiveFields: true
        fieldNames: [
          "LORA_RSSI",
          "LORA_SNR",
          "LORA_DATARATE",
          "BATTERY",
          "DISTANCE",
          "POSITION",
          "FILL_LEVEL",
          "LOCATION",
          "UNLOADED_AT"
        ]
      ) {
        value
        valueString
        modified
        field {
          fieldType
          verboseFieldName
          fieldName
          unit
          role
        }
      }
      history (
        timerangestart: $timerangestart
        timerangeend: $timerangeend
        resolution: $resolution
      )
      currentConfigurationValues {
        valueNumber
        configurationField {
          verboseFieldName
          fieldName
          unit
        }
      }
      product {
        id
        name
        slug
      }
    }
  }
`

export type DeviceData = { device: ApiDevice | null }

/**
 * [Specific Device]{@link https://docs.datacake.de/api/graphql-api/using-graphql#specific-device}
 */
export function useDevice(
  deviceId: string,
  shouldFetch: boolean = true,
  config?: SWRConfiguration<DeviceData, TransferError>
) {
  const apiToken = useAuthStore((state) => state.apiToken)
  const historyDateRange = getHistoryDateRange()

  const { data, error, isLoading, isValidating, mutate } = useSWR<DeviceData, TransferError>(
    shouldFetch
      ? {
          query,
          variables: {
            deviceId,
            timerangestart: historyDateRange.start.toISOString(),
            timerangeend: historyDateRange.end.toISOString(),
            // Average periods, one of: 'raw' | '<number><m|h|d>' (e.g. 5m, 1h, 1d)
            resolution: 'raw',
          },
          apiToken,
        }
      : null,
    config
  )

  useRevalidateOnViewEnter(mutate, isLoading, isValidating, config?.revalidateOnMount)

  return {
    data: useMemo(
      () => data?.device && (isListItemApiDeviceSupported(data.device) ? transformDevice(data.device) : null),
      [data]
    ),
    error,
    isLoading,
    isValidating,
    mutate,
  }
}

/**
 * Get date range for last 24 hours
 * Using full hours so SWR may key cache results
 */
function getHistoryDateRange(): { start: Date; end: Date } {
  const ref = new Date()

  const end = new Date(ref.setMinutes(0, 0, 0))
  const start = new Date(ref.setHours(ref.getHours() - 24))

  return { end, start }
}

import type { FunctionComponent } from 'preact'
import { IonIcon, IonItem, IonLabel } from '@ionic/react'
import { logOutOutline } from 'ionicons/icons'
import { useSWRConfig } from 'swr'

import { useAuthStore } from '../../store/authStore.ts'

export const SignOutItem: FunctionComponent = () => {
  const setAuth = useAuthStore((state) => state.setAuth)
  const { cache } = useSWRConfig()

  const handleSignOutClick = () => {
    // Remove cached requests where user taken was used
    for (const key of cache.keys()) {
      cache.delete(key)
    }

    setAuth(null)
  }

  return (
    <IonItem button color="light" onClick={handleSignOutClick}>
      <IonIcon aria-hidden="true" slot="start" icon={logOutOutline} />
      <IonLabel>{'Sign out'}</IonLabel>
    </IonItem>
  )
}

import type { FunctionComponent } from 'preact'
import { useRouteMatch } from 'react-router'

import { Page } from '../../components/Page/Page.tsx'
import { ContentMessage } from '../../components/ContentMessage/ContentMessage.tsx'

export const NotFoundPage: FunctionComponent = () => {
  const { url } = useRouteMatch()

  return (
    <Page title={'Not found'}>
      <ContentMessage message={`Page '${url}' not found`} type="warning" />
    </Page>
  )
}

import { useState } from 'preact/hooks'
import { useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react'

/**
 * True when page will enter, false when page did leave.
 * Requires IonPage component
 * @see https://ionicframework.com/docs/react/lifecycle
 *
 * @note Even that useIonView* hooks accept deps, lifecycle callbacks are not re-run on change
 *       See https://github.com/ionic-team/ionic-framework/issues/21792
 *
 * @note On Back navigation (and ion-back-button) component is unmounted and doesn't receive state update
 *       For cleanup use useIonViewWillLeave or useIonViewDidLeave hook
 */
export const useIonViewVisibility = (deps: any[] = []): boolean => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useIonViewWillEnter(() => setIsVisible(true), deps)
  useIonViewDidLeave(() => setIsVisible(false), deps)

  return isVisible
}

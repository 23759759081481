import type { FunctionComponent } from 'preact'
import { useMemo } from 'preact/hooks'
import { memo } from 'preact/compat'

import type { Position } from '@capacitor/geolocation'

import { Circle, LayerGroup, Marker } from 'react-leaflet'
import type { LatLngExpression } from 'leaflet'

import { myPositionIcon } from '../MapMarkerIcon/MyPositionIcon.ts'
import { useMapStore } from '../../store/mapStore.ts'

import './MyPositionMarker.css'

const MyPositionMarkerComponent: FunctionComponent<{
  geolocationPosition: Position
}> = ({ geolocationPosition }) => {
  const coords: LatLngExpression = useMemo(
    () => ({ lat: geolocationPosition.coords.latitude, lng: geolocationPosition.coords.longitude }),
    [geolocationPosition.coords.latitude, geolocationPosition.coords.longitude]
  )

  const isMyPositionEnabled = useMapStore((state) => state.isMyPositionEnabled)

  return (
    <LayerGroup>
      <Marker position={coords} icon={myPositionIcon(geolocationPosition, isMyPositionEnabled)} zIndexOffset={20} />
      {isMyPositionEnabled && (
        <Circle
          center={coords}
          radius={geolocationPosition.coords.accuracy}
          className="sb-map-marker-my-position-circle"
        />
      )}
    </LayerGroup>
  )
}

export const MyPositionMarker = memo(
  MyPositionMarkerComponent,
  ({ geolocationPosition: prevPosition }, { geolocationPosition: nextPosition }) =>
    prevPosition.coords.latitude === nextPosition.coords.latitude &&
    prevPosition.coords.longitude === nextPosition.coords.longitude &&
    prevPosition.coords.accuracy === nextPosition.coords.accuracy
)

import type { FunctionComponent } from 'preact'

import { type TransferError } from '../../utils/Error/index.ts'
import { ContentMessage } from '../ContentMessage/ContentMessage.tsx'

/**
 * SWR fetch handler
 * @note On ConnectErrors swr will retry in background (see SWRConfiguration['shouldRetryOnError'])
 *       but still we provide button to retry manually
 */
export const FetchHandler: FunctionComponent<{
  error: TransferError | undefined
  isLoading: boolean
  isNotFound?: boolean
}> = ({ error, isLoading, isNotFound = false }) => {
  if (error) {
    return <ContentMessage message={error.name} type="warning" />
  }

  if (isLoading) {
    return <ContentMessage message={'Loading data …'} type="loading" />
  }

  if (isNotFound) {
    return <ContentMessage message={'Item not found'} />
  }

  return null
}

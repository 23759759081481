import type { FunctionComponent } from 'preact'
import {
  type RefresherEventDetail,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import type { IonRefresherCustomEvent } from '@ionic/core'
import { KeyedMutator } from 'swr'

import { routes } from '../../navigation/index.ts'

/**
 * Ionic page wrapper
 * @note On desktop refreshing makes content being selected
 *       There is no event emitted on pull cancel so it's not possible to unset possible user-select css prop
 */
export const Page: FunctionComponent<{
  title: string
  noBackButton?: boolean
  /** Do not use large titles on iOS, fullscreen and scrollY */
  fitContent?: boolean
  onRefresh?: KeyedMutator<any>
}> = ({ title, noBackButton = false, fitContent = false, onRefresh = undefined, children }) => {
  const handleRefreshEnd = (event: IonRefresherCustomEvent<RefresherEventDetail>) => {
    onRefresh?.().finally(event.detail.complete)
  }

  return (
    <IonPage>
      <IonHeader translucent={!fitContent}>
        <IonToolbar>
          <IonTitle role="heading" aria-level={1}>
            {title}
          </IonTitle>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {!noBackButton && (
            <IonButtons slot="end">
              <IonBackButton defaultHref={routes.start} />
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      {fitContent ? (
        <IonContent scrollY={false}>{children as JSX.Element}</IonContent>
      ) : (
        <IonContent fullscreen={true}>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{title}</IonTitle>
            </IonToolbar>
          </IonHeader>

          {onRefresh && (
            <IonRefresher slot="fixed" onIonRefresh={handleRefreshEnd}>
              <IonRefresherContent />
            </IonRefresher>
          )}

          {children as JSX.Element}
        </IonContent>
      )}
    </IonPage>
  )
}

import type { FunctionComponent } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import { type LatLngBounds, type FitBoundsOptions, LatLng } from 'leaflet'
import { useMapEvent } from 'react-leaflet'
import { useShallow } from 'zustand/react/shallow'

import { useMapStore } from '../../store/mapStore.ts'

/**
 * Update user bounds ref on move
 * Fit map to bounds on workspace change
 *
 * @link https://react-leaflet.js.org/docs/example-external-state/
 */
export const MapEvents: FunctionComponent<{
  currentWorkspaceId: string | null
  mapBounds: LatLngBounds | undefined
  boundsOptions: FitBoundsOptions | undefined
}> = ({ currentWorkspaceId, mapBounds, boundsOptions }) => {
  const map = useMapEvent('moveend', handleBoundsChange)

  const userMapBoundsRef = useRef<LatLngBounds | undefined>(undefined)
  const setStoreMapBounds = useMapStore((state) => state.setMapBounds)

  const [isMyPositionEnabled, myPosition] = useMapStore(
    useShallow((state) => [state.isMyPositionEnabled, state.myPosition])
  )

  // Note: Bounds are not included in effect deps, so last value for a workspace is used
  useEffect(() => {
    if (mapBounds) {
      map.fitBounds(mapBounds, boundsOptions)
    }

    // Store bounds on workspace change and unmount
    // Note: Cleanup must happen in same effect as fitBounds
    return () => currentWorkspaceId && setStoreMapBounds(currentWorkspaceId, userMapBoundsRef.current)
  }, [currentWorkspaceId])

  // Fly to my position on user action using accuracy to compute zoom level
  useEffect(() => {
    if (isMyPositionEnabled && myPosition) {
      const latLng = new LatLng(myPosition.coords.latitude, myPosition.coords.longitude)
      const bounds = latLng.toBounds(myPosition.coords.accuracy * 2)

      map.flyTo(latLng, map.getBoundsZoom(bounds))
    }
  }, [isMyPositionEnabled, myPosition])

  return null

  function handleBoundsChange() {
    const userMapBounds = map.getBounds()

    if (userMapBoundsRef.current && userMapBoundsRef.current.equals(userMapBounds)) {
      return
    }

    userMapBoundsRef.current = userMapBounds
  }
}

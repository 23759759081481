import { type MarkerCluster, DivIconOptions } from 'leaflet'
import { DeviceMarkerIcon } from './DeviceMarkerIcon.ts'

import { MarkerIcon } from './MarkerIcon.ts'

import './MarkerClusterIcon.css'

/**
 * Marker cluster icon
 * Based on defaultIconCreateFunction
 */
export class MarkerClusterIcon extends MarkerIcon {
  /**
   * @inheritdoc
   */
  constructor(
    protected markerCluster: MarkerCluster,
    size: number = 40,
    options?: DivIconOptions
  ) {
    super({
      html: `
        <div>
          <span part="value"></span>
        </div>
      `,
      iconSize: [size, size],
      shadowSize: [size * 1.5, size * 1.5],
      ...options,
    })
  }

  /**
   * @inheritdoc
   */
  override createIcon(icon?: HTMLElement): HTMLElement {
    if (!icon) {
      icon = document.createElement('div')

      icon.classList.add('leaflet-marker-icon', 'marker-cluster', 'sb-map-marker-cluster-icon')
      icon.innerHTML = this.options.html as string

      this.setIconStyles(icon, this.options.iconSize!)
    }

    const childCount = this.markerCluster.getChildCount()

    // Set class names
    icon.classList.toggle('marker-cluster-small', childCount < 10)
    icon.classList.toggle('marker-cluster-medium', childCount >= 10 && childCount < 100)
    icon.classList.toggle('marker-cluster-large', childCount >= 100)

    // Ser value
    const valueElement: HTMLElement | null = icon.querySelector('[part="value"]')

    if (!valueElement) {
      return icon
    }

    // Compute average fill level
    const fillLevels = this.markerCluster
      .getAllChildMarkers()
      .map((marker) => marker.getIcon())
      .filter((icon): icon is DeviceMarkerIcon => icon instanceof DeviceMarkerIcon)
      .filter((icon) => icon.device.measurements['POSITION'].valueString !== 'tilt')
      .map((icon) => icon.device.measurements['FILL_LEVEL'])

    const avgFillLevel: number | undefined = fillLevels.length
      ? fillLevels.reduce((prevValue, fillLevel) => prevValue + fillLevel.value, 0) / fillLevels.length
      : undefined

    valueElement.textContent = `${childCount}; ${avgFillLevel?.toFixed(0) ?? '?'}%`

    return icon
  }
}

/**
 * Marker cluster icon factory function
 */
export function markerClusterIcon(...params: ConstructorParameters<typeof MarkerClusterIcon>) {
  return new MarkerClusterIcon(...params)
}

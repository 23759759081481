import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import type { Workspace } from '../data/types/workspace.ts'
import { createIdbStorage } from './persistent-storage/idbStorage.ts'

import { name as appName } from '../../package.json'

export type ApiToken = string
export type User = { fullName: string }
export type Auth = { token: ApiToken; user: User }

interface AuthStore {
  apiToken: ApiToken | null
  user: User | null

  setAuth: (auth: Auth | null) => void

  currentWorkspaceId: Workspace['id'] | null
  setCurrentWorkspaceId: (workspaceId: Workspace['id'] | null) => void
}

export const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      (set) => ({
        apiToken: null,
        user: null,
        setAuth: (auth) => {
          set(
            auth ? { apiToken: auth.token, user: auth.user } : { apiToken: null, user: null, currentWorkspaceId: null }
          )
        },

        currentWorkspaceId: null,
        setCurrentWorkspaceId: (currentWorkspaceId) => set({ currentWorkspaceId }),
      }),
      {
        /**
         * Use unique local storage name to not mix up data on development with other projects
         * @note on native, use just 'state'
         */
        // name: `${appName}/state`.replace(/\//g, '.'),
        // storage: createJSONStorage(createCapacitorPreferencesJSONStorage),
        name: 'state',
        storage: createIdbStorage(`${appName}/state`, 'keyval'),
        // Skip functions
        partialize: ({ apiToken, user, currentWorkspaceId }) => ({ apiToken, user, currentWorkspaceId }),
        version: 0,
        migrate: undefined,
      }
    ),
    { name: document.title, store: 'AuthStore' }
  )
)

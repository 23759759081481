import type { FunctionComponent } from 'preact'
import { useMemo } from 'preact/hooks'
import { memo } from 'preact/compat'
import { useHistory } from 'react-router'
import { Marker, Tooltip } from 'react-leaflet'

import type { WasteListItemDevice, HasPosition } from '../../data/types/device.ts'

import { deviceMarkerIcon } from '../MapMarkerIcon/DeviceMarkerIcon.ts'
import { routes, generatePath } from '../../navigation/index.ts'

/**
 * @note for icon transitions, must use same icon instance
 */
const DeviceMapMarkerComponent: FunctionComponent<{
  device: WasteListItemDevice & HasPosition
}> = ({ device }) => {
  const history = useHistory()

  // Memoise position object, otherwise marker and it's icon is always re-added
  const position = useMemo(() => device.position, [device.position.lat, device.position.lng])

  // Note: Device memoization handled by memo
  const icon = useMemo(() => deviceMarkerIcon(device), [device.online, device.measurements])

  // Handle marker click
  const handleMarkerClick = (device: WasteListItemDevice) =>
    history.push(generatePath(routes.device, { id: device.id }))

  return (
    <Marker icon={icon} position={position} eventHandlers={{ click: () => handleMarkerClick(device) }}>
      {device.location && <Tooltip>{device.location}</Tooltip>}
    </Marker>
  )
}

export const DeviceMapMarker = memo(DeviceMapMarkerComponent, (prevProps, nextProps) => {
  return (
    prevProps.device.id === nextProps.device.id &&
    prevProps.device.online === nextProps.device.online &&
    prevProps.device.position.lat === nextProps.device.position.lat &&
    prevProps.device.position.lng === nextProps.device.position.lng &&
    prevProps.device.measurements['BATTERY'].value === nextProps.device.measurements['BATTERY'].value &&
    prevProps.device.measurements['FILL_LEVEL'].value === nextProps.device.measurements['FILL_LEVEL'].value &&
    prevProps.device.measurements['POSITION'].valueString === nextProps.device.measurements['POSITION'].valueString
  )
})

/**
 * Format date
 */
export function formatTime(date: Date): string {
  return date.toLocaleTimeString('pl-PL', {
    timeStyle: 'short',
  })
}

/**
 * Foramt date and time
 */
export function formatDateTime(date: Date): string {
  return date.toLocaleString('pl-PL', {
    dateStyle: 'short',
    timeStyle: 'short',
  })
}

import type { FunctionComponent } from 'preact'
import type { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core'
import { IonSelect, IonSelectOption } from '@ionic/react'
import { menuController } from '@ionic/core/components'

import { useWorkspaces } from '../../data/workspaces.ts'
import { useAuthStore } from '../../store/authStore.ts'

export const WorkspaceSelect: FunctionComponent<{ menu?: string }> = ({ menu }) => {
  const { data: workspaces, isLoading } = useWorkspaces()

  const { currentWorkspaceId, setCurrentWorkspaceId } = useAuthStore((state) => ({
    currentWorkspaceId: state.currentWorkspaceId,
    setCurrentWorkspaceId: state.setCurrentWorkspaceId,
  }))

  const handleWorkspaceChange = (event: IonSelectCustomEvent<SelectChangeEventDetail<string>>) => {
    setCurrentWorkspaceId(event.detail.value)

    menu && menuController.close(menu)
  }

  return (
    <IonSelect
      disabled={!workspaces?.length}
      label="Workspace"
      labelPlacement="stacked"
      placeholder={isLoading ? 'Loading data …' : 'Select workspace'}
      value={currentWorkspaceId}
      onIonChange={handleWorkspaceChange}
    >
      {workspaces?.map((workspace) => (
        <IonSelectOption key={workspace.id} value={workspace.id}>
          {workspace.name}
        </IonSelectOption>
      ))}
    </IonSelect>
  )
}

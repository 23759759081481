import { type PointExpression, DivIcon, point } from 'leaflet'

/**
 * @note The icon element must have `leaflet-marker-icon` or `leaflet-marker-shadow` class so animation work
 */
export abstract class MarkerIcon extends DivIcon {
  /**
   * @see Icon._setIconStyles
   */
  protected setIconStyles(element: HTMLElement, sizeExpression: PointExpression): this {
    const size = point(sizeExpression)
    const anchor = size.divideBy(2)

    // Set anchor
    element.style.marginLeft = `${-anchor.x}px`
    element.style.marginTop = `${-anchor.y}px`

    // Set size
    element.style.width = `${size.x}px`
    element.style.height = `${size.y}px`

    return this
  }
}

/**
 * Based on @changey/react-leaflet-markercluster, but
 * - works with preact
 * - is type-safe
 */

import { type MarkerClusterGroupOptions, MarkerClusterGroup as LeafletMarkerClusterGroup } from 'leaflet'
import { type EventedProps, createLayerComponent, createElementObject, extendContext } from '@react-leaflet/core'

import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'

export interface MarkerClusterGroupProps extends MarkerClusterGroupOptions, EventedProps {
  // Cast preact.ComponentChild to React.ReactNode
  children?: React.ReactNode
}

/**
 *  MarkerClusterGroup Component
 *
 * @link https://react-leaflet.js.org/docs/core-architecture/
 * @link https://github.com/PaulLeCam/react-leaflet/issues/897
 * @link https://github.com/Leaflet/Leaflet.markercluster
 *
 * @note using eventHandlers prop adds handlers on markers, not group
 */
export const MarkerClusterGroup = createLayerComponent<LeafletMarkerClusterGroup, MarkerClusterGroupProps>(
  function createMarkerCluster({ children: _c, eventHandlers, ...options }, context) {
    const markerClusterGroup = new LeafletMarkerClusterGroup(options)

    return createElementObject(markerClusterGroup, extendContext(context, { layerContainer: markerClusterGroup }))
  },
  function updateMarkerCluster(markerClusterGroup, props, prevProps) {
    // Refresh clusters when markers change. Basically any change affects cluster icon
    // See https://www.npmjs.com/package/leaflet.markercluster#refreshing-the-clusters-icon
    if (props.children !== prevProps.children) {
      markerClusterGroup.refreshClusters()
    }
  }
)

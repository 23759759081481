import { listSharp, listOutline, mapSharp, mapOutline } from 'ionicons/icons'

import { routes } from './routes.ts'

export interface MenuItem {
  title: string
  /** Path */
  url: string
  /** Material dessign icon */
  mdIcon: string
  /** iOS icon */
  iosIcon: string
}

export const menuItems: MenuItem[] = [
  {
    title: 'devices list',
    url: routes.devices,
    mdIcon: listSharp,
    iosIcon: listOutline,
  },
  {
    title: 'devices map',
    url: routes.devicesMap,
    mdIcon: mapSharp,
    iosIcon: mapOutline,
  },
] as const

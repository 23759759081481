import type { FunctionComponent } from 'preact'
import { IonBadge, IonIcon, IonList, IonItem, IonLabel } from '@ionic/react'
import { alertCircle, batteryDead, batteryHalf, batteryFull } from 'ionicons/icons'

import { wifi } from 'ionicons/icons'

import { routes, generatePath } from '../../navigation/index.ts'

import { useAuthStore } from '../../store/authStore.ts'
import { useDevices } from '../../data/devices.ts'
import { useIonViewVisibility } from '../../hooks/useIonViewVisibility.ts'
import { isWasteListItemDevice } from '../../data/filters/device.ts'
import type {
  BatteryLevel as BatteryLevelMeasurement,
  BatteryVoltage as BatterVoltageMeasurement,
} from '../../data/types/measurement.ts'
import type { FillLevel as FillLevelMeasurement } from '../../data/product/milesight-em310-udl/measurement.ts'
import { getFillLevelNorm, FillLevelNorm } from '../../data/product/milesight-em310-udl/helpers.ts'

import { Page } from '../../components/Page/Page.tsx'
import { ContentMessage } from '../../components/ContentMessage/ContentMessage.tsx'
import { FetchHandler } from '../../components/FetchHandler/FetchHandler.tsx'

export const DevicesPage: FunctionComponent = () => {
  const currentWorkspaceId = useAuthStore((state) => state.currentWorkspaceId)

  const isViewVisible = useIonViewVisibility()

  const { data: devices, error, isLoading, mutate } = useDevices(currentWorkspaceId, isViewVisible)

  return (
    <Page title={'Devices'} noBackButton onRefresh={mutate}>
      {devices ? (
        devices.length ? (
          <IonList inset>
            {devices.map((item) => (
              <IonItem key={item.id} button detail routerLink={generatePath(routes.device, { id: item.id })}>
                {/** Online indicator */}
                <IonIcon aria-label="Online" icon={wifi} slot="start" color={item.online ? 'success' : 'medium'} />
                {/** Location */}
                {item.location ? (
                  <IonLabel>
                    {item.location}
                    <p>{item.verboseName}</p>
                  </IonLabel>
                ) : (
                  <IonLabel color="medium">
                    {'Location description missing'}
                    <p>{item.verboseName}</p>
                  </IonLabel>
                )}
                {isWasteListItemDevice(item) &&
                  (item.measurements['POSITION'].valueString !== 'tilt' ? (
                    // Fill level
                    <IonBadge slot="end" color={getFillLevelColor(item.measurements['FILL_LEVEL'])}>
                      {item.measurements['FILL_LEVEL'].value} %
                    </IonBadge>
                  ) : (
                    // Failing detection
                    <IonIcon aria-hidden="true" slot="end" color="danger" icon={alertCircle} />
                  ))}
                {/** Battery */}
                <IonIcon
                  aria-hidden="true"
                  slot="end"
                  {...getBatteryIconProps(
                    // TODO: Try to index
                    'BATTERY' in item.measurements
                      ? (item.measurements['BATTERY'] as BatteryLevelMeasurement | BatterVoltageMeasurement)
                      : undefined
                  )}
                />
              </IonItem>
            ))}
          </IonList>
        ) : (
          <ContentMessage message={'The workspace does not contain any device'} type="note" />
        )
      ) : (
        <FetchHandler error={error} isLoading={isLoading} />
      )}
    </Page>
  )
}

/**
 * Fill level norm indicator color
 */
function getFillLevelColor(fillLevelMeasurement: FillLevelMeasurement): string {
  switch (getFillLevelNorm(fillLevelMeasurement.value)) {
    case FillLevelNorm.HIGH:
      return 'fill-norm-high'
    case FillLevelNorm.MEDIUM:
      return 'fill-norm-medium'
    case FillLevelNorm.LOW:
    default:
      return 'fill-norm-low'
  }
}

/**
 * Battery icon and color
 */
function getBatteryIconProps(batteryMeasurement?: BatteryLevelMeasurement | BatterVoltageMeasurement): {
  icon: string
  color: string
} {
  if (batteryMeasurement?.field.unit !== '%') {
    return { icon: batteryHalf, color: 'medium' }
  }

  if (batteryMeasurement.value > 66) {
    return { icon: batteryFull, color: 'success' }
  }

  if (batteryMeasurement.value > 33) {
    return { icon: batteryHalf, color: 'warning' }
  }

  return { icon: batteryDead, color: 'danger' }
}

/**
 * Available norms
 */
export enum FillLevelNorm {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

/**
 * Get fill level norm from value
 * @note Thresholds could be configurable per device depending on bin's shape (ie. trapezoid)
 * @param {number} fillLevel - Fill level in percentage
 * @param {[number, number]} normThresholds - Tuple of high and medium threshold
 * @return {FillLevelNorm}
 */
export function getFillLevelNorm(fillLevel: number, normThresholds: [number, number] = [66, 33]): FillLevelNorm {
  const [normHighThreshold, normMediumThreshold] = normThresholds

  if (fillLevel > normHighThreshold) {
    return FillLevelNorm.HIGH
  }

  if (fillLevel > normMediumThreshold) {
    return FillLevelNorm.MEDIUM
  }

  return FillLevelNorm.LOW
}

import type { FunctionComponent } from 'preact'
import { IonItem, IonLabel, IonThumbnail } from '@ionic/react'

import iconUrl from '../../assets/images/icon.svg'
import './Logotype.css'

/**
 * Side menu logotype
 */
export const Logotype: FunctionComponent = () => (
  <IonItem lines="none">
    <IonThumbnail slot="start" className="sb-logotype-logo">
      <img src={iconUrl} alt="SmartBin" />
    </IonThumbnail>
    <IonLabel className="sb-logotype-brand">{'SmartBin'}</IonLabel>
  </IonItem>
)

import type { FunctionComponent } from 'preact'
import { useMemo } from 'preact/hooks'

import { formatDateTime, formatTime } from '../../utils/date.ts'

import type { MilesightEM310UDLDevice } from '../../data/product/milesight-em310-udl/device.ts'
import { getFillLevelNorm } from '../../data/product/milesight-em310-udl/helpers.ts'

import 'charts.css/dist/charts.css'
import './FillLevelChart.css'

/**
 * Fill level column chart
 * @todo Skip bars on position:tilt
 */
export const FillLevelChart: FunctionComponent<{
  history: MilesightEM310UDLDevice['history']
}> = ({ history }) => {
  const itemsColumn: Array<{ time: Date; fillLevel: number; isTilted: boolean; unloadedAt: number | null }> = useMemo(
    () =>
      history
        // Remove nulls when using resolution: raw
        .filter(({ measurements }) => measurements['FILL_LEVEL'] !== null && measurements['POSITION_INT'] !== null)
        .map(({ measurements, time }) => ({
          time,
          fillLevel: measurements['FILL_LEVEL'],
          isTilted: measurements['POSITION_INT'] > 0,
          unloadedAt: measurements['UNLOADED_AT'] ?? null,
        })),
    [history]
  )

  /*
  // Use this with '--start': prevFillLevel / 100, '--end': fillLevel / 100
  const itemsArea: Array<{ time: Date; fillLevel: number; prevFillLevel: number; isTilted: boolean }> = history
    .slice(1)
    .map((historyItem, index) => ({
      time: historyItem.time,
      fillLevel: historyItem.measurements['FILL_LEVEL'],
      prevFillLevel: history[index].measurements['FILL_LEVEL'],
      isTilted: historyItem.measurements['POSITION_INT'] > 0,
    }))
  */

  return (
    <>
      <p>{'History'}</p>

      <div className="sb-fill-level-chart">
        {/** Wrapper */}
        <div className="sb-fill-level-chart-wrapper">
          {/** Chart */}
          <table className="charts-css column show-labels show-primary-axis show-10-secondary-axes data-spacing-1 data-end">
            {/** Heading */}
            <caption>{'Fill levels'}</caption>
            <thead>
              <tr>
                <th scope="col">{'Time'}</th>
                <th scope="col">{'Fill Level'}</th>
              </tr>
            </thead>
            <tbody>
              {itemsColumn.map(({ time, fillLevel, isTilted, unloadedAt }, index) => (
                <tr key={index}>
                  {/** Label */}
                  <th className={['label', index % 2 ? 'hide-label' : ''].join(' ')} scope="row">
                    {formatTime(time)}
                  </th>
                  {/** Bar */}
                  <td
                    className={[
                      'bar',
                      `bar--fill-norm-${getFillLevelNorm(fillLevel)}`,
                      isTilted ? 'bar--position-tilt' : '',
                    ].join(' ')}
                    style={{ '--size': fillLevel / 100 }}
                  >
                    {/** Data */}
                    <span className={['data', fillLevel < 15 ? 'outside' : 'inside'].join(' ')}>
                      {fillLevel.toFixed(0)} %
                    </span>
                    {/** Tooltip */}
                    <div className="tooltip">
                      <p className="tooltip__description">{formatDateTime(time)}</p>
                      <ul className="tooltip__list">
                        <li>
                          {'Fill level:'} {fillLevel.toFixed(0)} %
                        </li>
                        {unloadedAt !== null && (
                          <li>
                            {'Unloaded at: '} {unloadedAt} %
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/** Legend */}
        <ul className="charts-css legend legend-inline legend-circle">
          <li className="legend-item--fill-norm-high">{'High'}</li>
          <li className="legend-item--fill-norm-medium">{'Medium'}</li>
          <li className="legend-item--fill-norm-low">{'Low'}</li>
          <li className="legend-item--position-tilt">{'Tilt'}</li>
        </ul>
      </div>
    </>
  )
}

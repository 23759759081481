import type { FunctionComponent } from 'preact'
import { createContext } from 'preact'
import { useContext } from 'preact/hooks'
import { SWRConfig, type SWRConfiguration } from 'swr'

import { ConnectError, TransferError } from '../utils/Error/index.ts'
import { type ApiFetcher, createFetcher } from '../utils/fetcher.ts'

/**
 * Context object
 */
export const ApiContext = createContext<ApiFetcher | null>(null)

/**
 * Hook
 */
export function useApi(): ApiFetcher {
  const value = useContext(ApiContext)

  if (!value) {
    throw new Error('Api Context has not been provided')
  }

  return value
}

/**
 * Provider
 * @note Ionic card and sheet modals are stacked under ion-app, so this should wrap it
 */
export const ApiProvider: FunctionComponent<{
  apiUrl: string
  refreshInterval: number
  children: JSX.Element
}> = ({ apiUrl, refreshInterval, children }) => {
  const fetcher: ApiFetcher = createFetcher(apiUrl)

  const swrConfig: SWRConfiguration = {
    revalidateIfStale: false,
    revalidateOnMount: undefined,
    refreshInterval: refreshInterval,
    dedupingInterval: refreshInterval,
    shouldRetryOnError: (error: TransferError) => error instanceof ConnectError,
    fetcher,
  }

  return (
    <ApiContext.Provider value={fetcher}>
      <SWRConfig value={swrConfig}>{children}</SWRConfig>
    </ApiContext.Provider>
  )
}

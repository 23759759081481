import { useEffect } from 'preact/hooks'

import { useAuthStore } from '../store/authStore.ts'
import { useWorkspaces } from '../data/workspaces.ts'

/**
 * Set initial workspace in global state
 */
export const useFirstWorkspace = (enabled: boolean) => {
  const { data: workspaces } = useWorkspaces(enabled)

  const currentWorkspaceId = useAuthStore((state) => state.currentWorkspaceId)
  const setCurrentWorkspaceId = useAuthStore((state) => state.setCurrentWorkspaceId)

  // Set initial workspace in global state
  useEffect(() => {
    // Keep current if still available
    if (workspaces?.find(({ id }) => id === currentWorkspaceId)) {
      return
    }

    const firstWorskpace = workspaces?.at(0)

    if (!firstWorskpace) {
      return
    }

    setCurrentWorkspaceId(firstWorskpace.id)
  }, [currentWorkspaceId, setCurrentWorkspaceId, workspaces])
}

import type { FunctionComponent } from 'preact'
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
} from '@ionic/react'
import { useLocation } from 'react-router'

import { useAuthStore } from '../../store/authStore.ts'
import { menuItems } from '../../navigation/index.ts'
import { Logotype } from '../Logotype/Logotype.tsx'
import { UserAvatar } from '../UserAvatar/UserAvatar.tsx'
import { WorkspaceSelect } from '../WorkspaceSelect/WorkspaceSelect.tsx'
import { SignOutItem } from '../SignOutItem/SignOutItem.tsx'

export const Menu: FunctionComponent = () => {
  const user = useAuthStore((state) => state.user)
  const location = useLocation()

  return (
    <IonMenu contentId="main" disabled={false} menuId="main-menu" side="start" type="overlay">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <Logotype />
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        {/** Avatar & Workspace selector */}
        <IonList className="ion-no-padding" lines="none">
          <IonItem color="light">
            <UserAvatar slot="start" />
            <IonLabel>{user?.fullName}</IonLabel>
          </IonItem>
          <IonItem color="light">
            <WorkspaceSelect menu="main-menu" />
          </IonItem>
        </IonList>
        {/** Menu items */}
        <IonList lines="none">
          {menuItems.map((item) => (
            <IonMenuToggle key={item.url} autoHide={false} menu="main-menu">
              <IonItem
                color={location.pathname === item.url ? 'light' : undefined}
                routerLink={item.url}
                routerDirection="none"
                detail={false}
              >
                <IonIcon aria-hidden="true" slot="start" ios={item.iosIcon} md={item.mdIcon} />
                <IonLabel>{item.title}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          ))}
        </IonList>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonList className="ion-no-padding" lines="none">
            <SignOutItem />
          </IonList>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  )
}

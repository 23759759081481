import { type DivIconOptions } from 'leaflet'

import type { WasteListItemDevice } from '../../data/types/device.ts'
import { MarkerIcon } from './MarkerIcon.ts'
import { getFillLevelNorm, FillLevelNorm } from '../../data/product/milesight-em310-udl/helpers.ts'

import './DeviceMarkerIcon.css'

/**
 * Device marker icon factory
 * Holds device state for:
 * - element updates
 * - marker cluster stats
 */
export class DeviceMarkerIcon extends MarkerIcon {
  /**
   * @inheritdoc
   */
  constructor(
    public device: WasteListItemDevice,
    size: number = 32,
    options?: DivIconOptions
  ) {
    super({
      html: `
        <div part="base" class="sb-map-marker-icon-base">
          <span part="value" class="sb-map-marker-icon-base__value"></span>
          <span part="unit" class="sb-map-marker-icon-base__unit"></span>
        </div>
      `,
      iconSize: [size, size],
      shadowSize: [size * 1.5, size * 1.5],
      ...options,
    })
  }

  /**
   * Update existing icon element to allow transitions
   *
   * @inheritdoc
   */
  override createIcon(icon?: HTMLElement): HTMLElement {
    if (!icon) {
      icon = document.createElement('div')

      icon.classList.add('leaflet-marker-icon', 'sb-map-marker-icon')
      icon.innerHTML = this.options.html as string

      this.setIconStyles(icon, this.options.iconSize!)
    }

    const isPositionNormal: boolean = this.device.measurements['POSITION'].valueString !== 'tilt'
    const fillLevelValue: number = this.device.measurements['FILL_LEVEL'].value
    const fillLevelUnit: string = this.device.measurements['FILL_LEVEL'].field.unit
    const batteryLevelValue: number = this.device.measurements['BATTERY'].value

    const fillLevelNorm = getFillLevelNorm(fillLevelValue)

    const baseElement: HTMLElement | null = icon.querySelector('[part="base"]')

    if (!baseElement) {
      return icon
    }

    // Set class names
    baseElement.classList.toggle('sb-map-marker-icon-base--offline', !this.device.online)
    baseElement.classList.toggle('sb-map-marker-icon-base--position-tilt', !isPositionNormal)

    // Color indicators
    baseElement.classList.toggle('sb-map-marker-icon-base--fill-norm-high', fillLevelNorm == FillLevelNorm.HIGH)
    baseElement.classList.toggle('sb-map-marker-icon-base--fill-norm-medium', fillLevelNorm === FillLevelNorm.MEDIUM)
    baseElement.classList.toggle('sb-map-marker-icon-base--fill-norm-low', fillLevelNorm == FillLevelNorm.LOW)

    // Set properties
    baseElement.style.setProperty('--sb-measurement-fill-level', `${fillLevelValue ?? 0}%`)
    baseElement.style.setProperty('--sb-measurement-battery-level', `${batteryLevelValue ?? 0}%`)

    // Set content
    const valueElement = baseElement.querySelector('[part="value"]')
    const unitElement = baseElement.querySelector('[part="unit"]')

    if (!valueElement || !unitElement) {
      return icon
    }

    valueElement.textContent = isPositionNormal ? fillLevelValue.toFixed(0) : '!'
    unitElement.textContent = isPositionNormal ? fillLevelUnit || '?' : ''

    return icon
  }

  /**
   * Hack to use shadow on L.DivIcon
   *
   * @inheritdoc
   */
  override createShadow(icon?: HTMLElement): HTMLElement {
    if (!icon) {
      icon = document.createElement('span')

      icon.classList.add('leaflet-marker-shadow', 'sb-map-marker-shadow')

      this.setIconStyles(icon, this.options.shadowSize!)
    }

    return icon
  }
}

/**
 * Marker icon factory function
 */
export function deviceMarkerIcon(...params: ConstructorParameters<typeof DeviceMarkerIcon>) {
  return new DeviceMarkerIcon(...params)
}

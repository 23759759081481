import TransferError from './TransferError.ts'

export default class ApiResponseError extends TransferError {
  public name = 'ApiResponseError'

  /**
   * @inheritdoc
   */
  constructor(
    request: Request,
    public response: Response,
    message = 'An error occured while fetching the data',
    options?: ErrorOptions
  ) {
    super(request, message, options)

    Object.setPrototypeOf(this, ApiResponseError.prototype)
  }
}

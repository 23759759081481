import { useState, useEffect } from 'preact/hooks'
import type { CapacitorException } from '@capacitor/core'
import { type Position, type PositionOptions, Geolocation } from '@capacitor/geolocation'

/**
 * Merge of web and capacitor native
 * @TODO Check what really comes up from native platforms
 *
 * example web code to native message mappsing:
 * - PERMISSION_DENIED == 'Location permission was denied'
 * - POSITION_UNAVAILABLE == 'Google Play Services not available' | 'location disabled'
 */
type PositionError = GeolocationPositionError | CapacitorException

type HookResult = {
  currentPosition: Position | null
  error: PositionError | null
}

/**
 * @see @https://capacitorjs.com/docs/apis/geolocation
 * @see https://github.com/capacitor-community/react-hooks/tree/main?tab=readme-ov-file#geolocation-hooks
 */
export const useWatchPosition = (shouldWatch: boolean, options: PositionOptions = {}): HookResult => {
  const [currentPosition, setCurrentPosition] = useState<Position | null>(null)
  const [error, setError] = useState<PositionError | null>(null)

  useEffect(() => {
    if (!shouldWatch) {
      return
    }

    let watchId: string | undefined

    // Note: Use may navigate back when permission prompt is open
    Geolocation.watchPosition(options, (position, error?: PositionError) => {
      setCurrentPosition(position)
      setError(error ?? null)
    }).then((id) => (watchId = id))

    return () => watchId && Geolocation.clearWatch({ id: watchId })
  }, [shouldWatch])

  return {
    currentPosition,
    error,
  }
}

import type { ApiDevice, ListItemDevice, Device } from '../../types/device.ts'

import { MilesightWS302Measurement } from './measurement.ts'

export const MilesightWS302DeviceProductName = 'Milesight WS302'

export type MilesightWS302ApiDevice = ApiDevice<MilesightWS302Measurement>

export type MilesightWS302ListItemDevice = ListItemDevice<MilesightWS302Measurement>
export type MilesightWS302Device = Device<MilesightWS302ListItemDevice>

import { useSyncExternalStore } from 'preact/compat'

/**
 * [Using the Page Visibility API]{@link https://developer.mozilla.org/en-US/blog/using-the-page-visibility-api/}
 */
export const usePageVisibility = (): boolean => {
  return useSyncExternalStore<boolean>(subscribe, getSnapshot)
}

function subscribe(callback: () => void) {
  window.addEventListener('visibilitychange', callback)

  return () => window.removeEventListener('visibilitychange', callback)
}

function getSnapshot(): boolean {
  return document.visibilityState === 'visible'
}

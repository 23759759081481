import type { FunctionComponent } from 'preact'
import { useMemo } from 'preact/hooks'

import { IonFab } from '@ionic/react'

import { type FitBoundsOptions, LatLngBounds } from 'leaflet'
import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet'

import { MarkerClusterGroup } from '../../components/MarkerClusterGroup/MarkerClusterGroup.tsx'
import { markerClusterIcon } from '../../components/MapMarkerIcon/MarkerClusterIcon.ts'

import type { HasPosition, WasteListItemDevice } from '../../data/types/device.ts'
import { isWasteListItemDevice } from '../../data/filters/device.ts'
import { useAuthStore } from '../../store/authStore.ts'
import { useMapStore } from '../../store/mapStore.ts'
import { useDevices } from '../../data/devices.ts'
import { useIonViewVisibility } from '../../hooks/useIonViewVisibility.ts'
import { useDarkMode } from '../../hooks/useDarkMode.ts'

import { Page } from '../../components/Page/Page.tsx'
import { ContentMessage } from '../../components/ContentMessage/ContentMessage.tsx'
import { FetchHandler } from '../../components/FetchHandler/FetchHandler.tsx'
import { MyPositionFabButton } from '../../components/MyPositionFabButton/MyPositionFabButton.tsx'
import { DeviceMapMarker } from '../../components/MapMarker/DeviceMapMarker.tsx'
import { MyPositionMarker } from '../../components/MapMarker/MyPositionMarker.tsx'
import { MapEvents } from '../../components/MapEvents/MapEvents.tsx'

import 'leaflet/dist/leaflet.css'
import './MapPage.css'

const tileLayerUrl: Record<'light' | 'dark', string> = {
  light: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
  dark: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
  // light: '#',
  // dark: '#',
}

const fitBoundsOptions: FitBoundsOptions = {
  // Pad bounds by the 1/2 size of the marker
  padding: [16, 16],
}

export const MapPage: FunctionComponent = () => {
  // Render map only when page is visible, so leaflet determines proper container dimensions
  const isViewVisible = useIonViewVisibility()

  const isDarkMode = useDarkMode()

  const myPosition = useMapStore((state) => state.myPosition)

  // Load data
  const currentWorkspaceId = useAuthStore((state) => state.currentWorkspaceId)
  const { data: devices, error, isLoading } = useDevices(currentWorkspaceId, isViewVisible) // Note: this makes map render on every enter

  /*
  // Simlulate new data
  useEffect(() => {
    const timer = window.setInterval(mutate, 15_000)
    return () => window.clearInterval(timer)
  }, [])
  */

  // Pick waste devices with position
  const devicesWithPosition: Array<WasteListItemDevice & HasPosition> | undefined = useMemo(
    () =>
      devices?.filter(
        (device): device is WasteListItemDevice & HasPosition =>
          isWasteListItemDevice(device) && device.position !== null
      ),
    [devices]
  )

  const storeMapBounds = useMapStore((state) => (currentWorkspaceId ? state.mapBounds[currentWorkspaceId] : undefined))

  const mapBounds: LatLngBounds | undefined = useMemo(
    () => storeMapBounds ?? getMapBoundsForDevices(devicesWithPosition),
    [storeMapBounds, devicesWithPosition]
  )

  return (
    <Page title={'Map'} fitContent>
      {/** My Position */}
      <IonFab slot="fixed" horizontal="start" vertical="bottom">
        <MyPositionFabButton isViewVisible={isViewVisible} />
      </IonFab>

      {/** Map */}
      {devices ? ( // Loaded
        mapBounds?.isValid() ? ( // Valid bounds
          <MapContainer
            className="sb-map-container"
            maxZoom={18}
            minZoom={3}
            tap={false}
            attributionControl={false}
            zoomControl={false}
          >
            <TileLayer
              attribution='© <a href="http://osm.org/copyright">OpenStreetMap</a> © <a href="https://carto.com/attributions">CARTO</a>'
              url={isDarkMode ? tileLayerUrl.dark : tileLayerUrl.light}
            />

            <AttributionControl />

            <MapEvents
              currentWorkspaceId={currentWorkspaceId}
              mapBounds={mapBounds}
              boundsOptions={storeMapBounds ? undefined : fitBoundsOptions}
            />

            {myPosition && <MyPositionMarker geolocationPosition={myPosition} />}

            <MarkerClusterGroup
              animate={true}
              chunkedLoading={true}
              showCoverageOnHover={false}
              spiderfyOnEveryZoom={false}
              iconCreateFunction={markerClusterIcon}
            >
              {useMemo(
                () => devicesWithPosition?.map((device) => <DeviceMapMarker key={device.id} device={device} />),
                [devicesWithPosition]
              )}
            </MarkerClusterGroup>
          </MapContainer>
        ) : (
          <ContentMessage message={'The workspace does not contain any device'} type="note" />
        )
      ) : (
        <FetchHandler error={error} isLoading={isLoading} />
      )}
    </Page>
  )
}

/**
 * Get map bounds for given devices
 */
function getMapBoundsForDevices(devices?: HasPosition[]): LatLngBounds | undefined {
  return devices?.length ? new LatLngBounds(devices.map(({ position }) => [position.lat, position.lng])) : undefined
}

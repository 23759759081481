import { FunctionComponent } from 'preact'
import { useErrorBoundary } from 'preact/hooks'
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react'

import { routes } from '../../navigation/index.ts'
import { Page } from '../Page/Page.tsx'

/**
 * Handle uncaught errors
 * @note Handled errors are still emitted by preact in dev mode
 * @note Code uses Preact-specific useErrorBoundary hook, React backport: https://www.npmjs.com/package/react-use-error-boundary
 * @note ion button doesn't use router at this point so state is reset to default
 * @example Test throwing an error
 * ```ts
 * const [throwError, setThrowError] = useState<boolean>(false)
 *
 * if (throwError) {
 *   throw new Error('test')
 * }
 *
 * return <button onClick={() => setThrowError(true)}>{'Throw an Error'}</button>
 * ```
 */
export const ErrorBoundary: FunctionComponent = ({ children }) => {
  const [error, resetError]: [Error, () => void] = useErrorBoundary((_error: Error, _errorInfo) => {
    // Notify log error service
  })

  // No-op
  if (!error) {
    return children as JSX.Element
  }

  return (
    <Page title="App Error">
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>{'App Error'}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <code>
            {error.name}: {error.message}
          </code>
          {error.stack && <pre>{error.stack.replace(error.toString(), 'Stack')}</pre>}
        </IonCardContent>
        <IonButton color="primary" fill="solid" routerLink={routes.start} routerDirection="none">
          {'Restart app'}
        </IonButton>
        <IonButton color="primary" fill="clear" onClick={resetError}>
          {'Try again'}
        </IonButton>
      </IonCard>
    </Page>
  )
}

import useSWR, { type SWRConfiguration } from 'swr'
import { useMemo } from 'preact/hooks'

import { useAuthStore } from '../store/authStore.ts'
import type { ListItemApiDevice } from './types/device.ts'
import { transformListItemDevice } from './transformers/device.ts'
import { isListItemApiDeviceSupported } from './filters/device.ts'
import { useRevalidateOnViewEnter } from '../hooks/useRevalidateOnViewEnter.ts'
import type { TransferError } from '../utils/Error/index.ts'

const query: string = `
  query AllDevices($workspace: String!) {
    allDevices(inWorkspace: $workspace) {
      id
      serialNumber
      verboseName
      location
      currentLocation {
        lat
        lng
      }
      metadata
      online
      lastHeard
      currentMeasurements(
        allActiveFields: true
        fieldNames: [
          "LORA_RSSI",
          "LORA_SNR",
          "LORA_DATARATE",
          "BATTERY",
          "DISTANCE",
          "POSITION",
          "FILL_LEVEL",
          "LOCATION",
          "UNLOADED_AT"
        ]
      ) {
        value
        valueString
        modified
        field {
          fieldType
          verboseFieldName
          fieldName
          unit
          role
        }
      }
      product {
        id
        name
        slug
      }
    }
  }
`

export type DevicesData = { allDevices: ListItemApiDevice[] }

/**
 * [All Devices in Workspace]{@link https://docs.datacake.de/api/graphql-api/using-graphql#all-devices-in-workspace}
 */
export function useDevices(
  workspace: string | null,
  shouldFetch: boolean = true,
  config?: SWRConfiguration<DevicesData, TransferError>
) {
  const apiToken = useAuthStore((state) => state.apiToken)

  const { data, error, isLoading, isValidating, mutate } = useSWR<DevicesData, TransferError>(
    workspace && shouldFetch ? { query, variables: { workspace }, apiToken } : null,
    config
  )

  useRevalidateOnViewEnter(mutate, isLoading, isValidating, config?.revalidateOnMount)

  return {
    data: useMemo(() => data?.allDevices.filter(isListItemApiDeviceSupported).map(transformListItemDevice), [data]),
    error,
    isLoading,
    isValidating,
    mutate,
  }
}

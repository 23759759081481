import type { FunctionComponent } from 'preact'
import { useState } from 'preact/hooks'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonInput,
  IonInputPasswordToggle,
  IonItem,
  IonList,
  IonPage,
  IonProgressBar,
  IonText,
  type InputChangeEventDetail,
} from '@ionic/react'
import { IonInputCustomEvent } from '@ionic/core'

import type { Credentials } from '../../data/types/credentials.ts'
import { useApi } from '../../data/ApiContext.tsx'
import { useAuthStore } from '../../store/authStore.ts'
import { type LoginData, query as loginQuery } from '../../data/login.ts'
import { AuthError, ConnectError } from '../../utils/Error/index.ts'

import iconUrl from '../../assets/images/icon.svg'

import './SignInPage.css'

export const SignInPage: FunctionComponent = () => {
  const fetcher = useApi()
  const setAuth = useAuthStore((state) => state.setAuth)

  const [credentials, setCredentials] = useState<Credentials>({
    email: '',
    password: '',
  })

  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const handleUsernameChange = (event: IonInputCustomEvent<InputChangeEventDetail>) =>
    setCredentials({
      ...credentials,
      email: event.detail.value ?? '',
    })

  const handlePasswordChange = (event: IonInputCustomEvent<InputChangeEventDetail>) =>
    setCredentials({
      ...credentials,
      password: event.detail.value ?? '',
    })

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (submitEvent) => {
    submitEvent.preventDefault()

    if (!credentials.email || !credentials.password) {
      return setError(new AuthError())
    }

    setIsAuthenticating(true)

    let data: LoginData

    try {
      data = await fetcher<LoginData>({
        query: loginQuery,
        variables: credentials,
      })
    } catch (transferError) {
      return setError(transferError as ConnectError)
    } finally {
      setIsAuthenticating(false)
    }

    setError(data.login.ok ? null : new AuthError())

    if (!data.login.ok) {
      return
    }

    setAuth({
      token: data.login.token,
      user: data.login.user,
    })
  }

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <div className="sb-center-wrapper">
          <IonCard className="sb-sign-in-card">
            {/** Logo */}
            <div className="sb-logo-container ion-margin ion-text-center">
              <img className="sb-logo-conainer__img" src={iconUrl} />
              <p className="sb-logo-container__headline">SmartBin</p>
            </div>
            {/** Loading indicator */}
            <IonProgressBar type="indeterminate" style={{ visibility: isAuthenticating ? 'visible' : 'hidden' }} />
            <IonCardHeader className="ion-text-center">
              <IonCardTitle>{'Sign in'}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <form onSubmit={handleSubmit}>
                <IonList lines="none">
                  {/** Username */}
                  <IonItem>
                    <IonInput
                      autocomplete="username"
                      disabled={isAuthenticating}
                      enterkeyhint="send"
                      inputMode="email"
                      label={'Email address'}
                      labelPlacement="stacked"
                      name="username"
                      placeholder="john@doe.com"
                      required
                      type="email"
                      onIonInput={handleUsernameChange}
                    />
                  </IonItem>

                  {/** Password */}
                  <IonItem>
                    <IonInput
                      autocomplete="current-password"
                      disabled={isAuthenticating}
                      enterkeyhint="send"
                      inputMode="text"
                      label={'Password'}
                      labelPlacement="stacked"
                      name="password"
                      placeholder="s3cr3t"
                      required
                      type="password"
                      onIonInput={handlePasswordChange}
                    >
                      <IonInputPasswordToggle slot="end" />
                    </IonInput>
                  </IonItem>
                </IonList>

                {/** Submit */}
                <IonButton
                  className="ion-margin-vertical"
                  disabled={isAuthenticating || !credentials.email || !credentials.password}
                  expand="block"
                  type="submit"
                >
                  {'Sign in'}
                </IonButton>

                {/** Error */}
                {error && !isAuthenticating && (
                  <div className="ion-text-center">
                    <IonText color="danger">
                      {error instanceof AuthError ? 'Invalid credentials' : 'Connection error'}
                    </IonText>
                  </div>
                )}
              </form>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  )
}

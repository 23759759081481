import type { DivIconOptions } from 'leaflet'
import type { Position } from '@capacitor/geolocation'

import { MarkerIcon } from './MarkerIcon.ts'

import './MyPositionIcon.css'

/**
 * My position icon
 * @note Showing radius using css requires computing size in pixels from meters
 *       WHich has to be recomputed after every zoom end
 *
 * @link https://leafletjs.com/examples/mobile/
 */
export class MyPositionIcon extends MarkerIcon {
  /**
   * @inheritdoc
   */
  constructor(
    protected gelocationPosition: Position,
    protected isEnabled: boolean,
    size: number = 18,
    options?: DivIconOptions
  ) {
    super({
      iconSize: [size, size],
      ...options,
    })
  }

  /**
   * @inheritdoc
   */
  override createIcon(icon?: HTMLElement): HTMLElement {
    if (!icon) {
      icon = document.createElement('div')

      icon.classList.add('leaflet-marker-icon', 'sb-map-marker-my-position-icon')

      this.setIconStyles(icon, this.options.iconSize!)
    }

    icon.classList.toggle('sb-map-marker-my-position-icon--disabled', !this.isEnabled)

    return icon
  }
}

/**
 * My position icon factory function
 */
export function myPositionIcon(...params: ConstructorParameters<typeof MyPositionIcon>) {
  return new MyPositionIcon(...params)
}

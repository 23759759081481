import type { FunctionComponent } from 'preact'
import { IonAvatar, IonIcon } from '@ionic/react'

import { person } from 'ionicons/icons'

import './UserAvatar.css'

/**
 * Based on demo in https://ionicframework.com/docs/api/avatar
 */
export const UserAvatar: FunctionComponent<{ slot: string }> = ({ slot }) => {
  return (
    <IonAvatar slot={slot} className="sb-user-avatar">
      <IonIcon aria-hidden="true" className="sb-user-avatar__icon" icon={person} />
    </IonAvatar>
  )
}

/**
 * @note Login is special type of query
 *       For example it's variables (credentials) should not be cached. SWR is not used here
 */

export const query: string = `
mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    ok
    token
    user {
      fullName
    }
  }
}
`

export type LoginData = {
  login:
    | {
        ok: true
        token: string
        user: { fullName: string }
      }
    | {
        ok: false
        token: null
        user: null
      }
}

import type { FunctionComponent } from 'preact'
import { useEffect } from 'preact/hooks'
import type { PositionOptions } from '@capacitor/geolocation'
import { useWatchPosition } from '../../hooks/useGelocation.ts'

import { IonFabButton, IonIcon, useIonToast } from '@ionic/react'
import { locationSharp, locationOutline } from 'ionicons/icons'

import { useMapStore } from '../../store/mapStore.ts'
import { usePageVisibility } from '../../hooks/usePageVisibility.ts'

const positionOptions: PositionOptions = {
  enableHighAccuracy: false,
  timeout: 10_000,
  maximumAge: 0,
}

/**
 * My position map button
 * @note Do not disable button when permissions are not granted because it's used to request permissions
 */
export const MyPositionFabButton: FunctionComponent<{ isViewVisible: boolean }> = ({ isViewVisible }) => {
  const isMyPositionEnabled = useMapStore((state) => state.isMyPositionEnabled)
  const setIsMyPositionEnabled = useMapStore((state) => state.setIsMyPosititionEnabled)

  const setMyPosition = useMapStore((state) => state.setMyPosition)

  const isPageVisible = usePageVisibility()

  const { currentPosition, error: currentPositionError } = useWatchPosition(
    isMyPositionEnabled && isViewVisible && isPageVisible,
    positionOptions
  )

  const [presentIonToast] = useIonToast()

  useEffect(() => {
    if (currentPosition) {
      setMyPosition(currentPosition)
    } else if (currentPositionError) {
      // Disable button on error
      setIsMyPositionEnabled(false)

      // User denied Geolocation
      // Note: on native platforms, should check for message
      if (currentPositionError.code === 1) {
        presentIonToast({ message: 'Location permission is not granted', color: 'warning' })
      }
    }
  }, [currentPosition, currentPositionError])

  const handleMyPositionClick = () => {
    setIsMyPositionEnabled(!isMyPositionEnabled)
  }

  return (
    <IonFabButton
      color={isMyPositionEnabled ? 'primary' : 'light'}
      aria-label="My location"
      onClick={handleMyPositionClick}
    >
      <IonIcon md={locationSharp} ios={locationOutline} />
    </IonFabButton>
  )
}

import { useIonViewWillEnter } from '@ionic/react'
import type { KeyedMutator } from 'swr'
import { useSWRConfig } from 'swr'

/**
 * Workaround for swr's revalidateOnMount with @ionic/react
 * Which doesn't mount/ unmount pages
 */
export const useRevalidateOnViewEnter = <Data>(
  mutate: KeyedMutator<Data>,
  isLoading: boolean,
  isValidating: boolean,
  localRevalidateOnMount: boolean = false
) => {
  const { revalidateOnMount = localRevalidateOnMount } = useSWRConfig()

  useIonViewWillEnter(() => {
    if (!isLoading && !isValidating && revalidateOnMount) {
      mutate()
    }
  }, [isLoading, isValidating, revalidateOnMount, mutate])
}
